import $ from 'jQuery';
global.$ = global.jQuery = $;
import Swiper, { EffectFade, Autoplay, Navigation } from 'swiper';
Swiper.use([EffectFade, Autoplay, Navigation]);

global.isDebug = false;
global.__c__ = function(a){
  if(isDebug){
    console.log(a);
  }
}

var _device = (function() {
  const ua = navigator.userAgent;
  if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
    return 'mobile';
  }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
    return 'tablet';
  }else{
    return 'desktop';
  }
})();

var pow = Math.pow,
  sqrt = Math.sqrt,
  sin = Math.sin,
  cos = Math.cos,
  PI = Math.PI,
  c1 = 1.70158,
  c2 = c1 * 1.525,
  c3 = c1 + 1,
  c4 = ( 2 * PI ) / 3,
  c5 = ( 2 * PI ) / 4.5;
// x is the fraction of animation progress, in the range 0..1
function bounceOut(x) {
  var n1 = 7.5625,
    d1 = 2.75;
  if ( x < 1/d1 ) {
    return n1*x*x;
  } else if ( x < 2/d1 ) {
    return n1*(x-=(1.5/d1))*x + 0.75;
  } else if ( x < 2.5/d1 ) {
    return n1*(x-=(2.25/d1))*x + 0.9375;
  } else {
    return n1*(x-=(2.625/d1))*x + 0.984375;
  }
}
$.extend( $.easing,
  {
    def: 'easeOutQuad',
    swing: function (x) {
      return $.easing[$.easing.def](x);
    },
    easeInQuad: function (x) {
      return x * x;
    },
    easeOutQuad: function (x) {
      return 1 - ( 1 - x ) * ( 1 - x );
    },
    easeInOutQuad: function (x) {
      return x < 0.5 ?
        2 * x * x :
        1 - pow( -2 * x + 2, 2 ) / 2;
    },
    easeInCubic: function (x) {
      return x * x * x;
    },
    easeOutCubic: function (x) {
      return 1 - pow( 1 - x, 3 );
    },
    easeInOutCubic: function (x) {
      return x < 0.5 ?
        4 * x * x * x :
        1 - pow( -2 * x + 2, 3 ) / 2;
    },
    easeInQuart: function (x) {
      return x * x * x * x;
    },
    easeOutQuart: function (x) {
      return 1 - pow( 1 - x, 4 );
    },
    easeInOutQuart: function (x) {
      return x < 0.5 ?
        8 * x * x * x * x :
        1 - pow( -2 * x + 2, 4 ) / 2;
    },
    easeInQuint: function (x) {
      return x * x * x * x * x;
    },
    easeOutQuint: function (x) {
      return 1 - pow( 1 - x, 5 );
    },
    easeInOutQuint: function (x) {
      return x < 0.5 ?
        16 * x * x * x * x * x :
        1 - pow( -2 * x + 2, 5 ) / 2;
    },
    easeInSine: function (x) {
      return 1 - cos( x * PI/2 );
    },
    easeOutSine: function (x) {
      return sin( x * PI/2 );
    },
    easeInOutSine: function (x) {
      return -( cos( PI * x ) - 1 ) / 2;
    },
    easeInExpo: function (x) {
      return x === 0 ? 0 : pow( 2, 10 * x - 10 );
    },
    easeOutExpo: function (x) {
      return x === 1 ? 1 : 1 - pow( 2, -10 * x );
    },
    easeInOutExpo: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        pow( 2, 20 * x - 10 ) / 2 :
        ( 2 - pow( 2, -20 * x + 10 ) ) / 2;
    },
    easeInCirc: function (x) {
      return 1 - sqrt( 1 - pow( x, 2 ) );
    },
    easeOutCirc: function (x) {
      return sqrt( 1 - pow( x - 1, 2 ) );
    },
    easeInOutCirc: function (x) {
      return x < 0.5 ?
        ( 1 - sqrt( 1 - pow( 2 * x, 2 ) ) ) / 2 :
        ( sqrt( 1 - pow( -2 * x + 2, 2 ) ) + 1 ) / 2;
    },
    easeInElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        -pow( 2, 10 * x - 10 ) * sin( ( x * 10 - 10.75 ) * c4 );
    },
    easeOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        pow( 2, -10 * x ) * sin( ( x * 10 - 0.75 ) * c4 ) + 1;
    },
    easeInOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        -( pow( 2, 20 * x - 10 ) * sin( ( 20 * x - 11.125 ) * c5 )) / 2 :
        pow( 2, -20 * x + 10 ) * sin( ( 20 * x - 11.125 ) * c5 ) / 2 + 1;
    },
    easeInBack: function (x) {
      return c3 * x * x * x - c1 * x * x;
    },
    easeOutBack: function (x) {
      return 1 + c3 * pow( x - 1, 3 ) + c1 * pow( x - 1, 2 );
    },
    easeInOutBack: function (x) {
      return x < 0.5 ?
        ( pow( 2 * x, 2 ) * ( ( c2 + 1 ) * 2 * x - c2 ) ) / 2 :
        ( pow( 2 * x - 2, 2 ) *( ( c2 + 1 ) * ( x * 2 - 2 ) + c2 ) + 2 ) / 2;
    },
    easeInBounce: function (x) {
      return 1 - bounceOut( 1 - x );
    },
    easeOutBounce: bounceOut,
    easeInOutBounce: function (x) {
      return x < 0.5 ?
        ( 1 - bounceOut( 1 - 2 * x ) ) / 2 :
        ( 1 + bounceOut( 2 * x - 1 ) ) / 2;
    }
  });

global._isSP = undefined;

if(window.outerWidth < 829){
  _isSP = true;
}else{
  _isSP = false;
}

var Init = function(){
  var $window = $(window);
  var $html = $("html");
  var $body = $("body");
  var $header = $("header");

  // COMMON Navigation
  var $nav = $(".nav-wrap");
  var $navButton = $(".nav--btn, .nav--close");
  var NavOpenClose = function(){
    if($body.hasClass("nav--open")){
      $html.removeClass("is-modal");
      $body.removeClass("nav--open");
      $nav.find('.nav-content').delay(300).animate({ scrollTop: 0 }, 0, function(){
        //ResetAnimate($nav);
      });
    }else{
      if(!$body.hasClass("nav--open")){
        $body.addClass("nav--open");
        $html.addClass("is-modal");
        //AnimationStart($nav);
      }
    }
  };
  $navButton.on("click", NavOpenClose);

  // TOP Swiper
  var howtoSlider = document.querySelector(".howto-wrap");
  if(howtoSlider){
    var pSwiper = new Swiper(howtoSlider.querySelector(".swiper"), {
      loop: true,
      slidesPerView: "auto",
      loopAdditionalSlides: 2,
      speed: 1500,
      autoplay: {
        delay: 1500,
        disableOnInteraction: false
      }
    });
  }


  // MODAL
  var modal = document.querySelectorAll(".modal");
  var modalSliderArr = [];
  var modalSwiperFunc = function(){
    if(modalSliderArr.length === 0){
      modal.forEach(function(elm, index){
        var _sliders = elm.querySelectorAll(".swiper");
        if(_sliders.length > 0){
          _sliders.forEach(function(sliderElm, index){
            modalSliderArr.push(
              new Swiper(sliderElm, {
                slidesPerView: "auto",
                autoHeight: true,
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
              })
            );
          });
        }
      });
    }else{
      modalSliderArr.forEach(function(elm, index){
        //console.log(elm);
      });
    }
  };
  modalSwiperFunc();
  var SelectModalOpen = function(event){
    // console.log("SelectModalOpen");
    var _$target = $(event.currentTarget);
    var _parentId = _$target.data("modal-id");
    var _id = _$target.data("modal-content");
    var $modal = $("#" + _parentId);
    var $modalOverlay = $modal.find(".modal-overlay");
    var $modalClose = $modal.find(".modal--close");
    var $modalContents = $modal.find(".modal-content");
    $html.addClass("is-modal");
    // console.log("SelectModalOpen");
    $modalContents.each(function(){
      var __$this = $(this);
      var __$box = __$this.find(".modal-box");
      if(__$this.data("modal-content") === _id){
        var _url = __$this.data("modal-url");
        var _color = __$this.data("modal-color");
        var _overlay = __$this.data("modal-overlay");
        var _opacity = __$this.data("modal-opacity") * 1;
        var _youtube = __$this.data("modal-youtube");

        if(_url !== void 0){
          __$this.css({
            "background": "url(" + _url + ") center center / 100% 100%"
          });
        }else if(_color !== void 0){
          __$this.css({
            "background": _color
          });
        }

        if(_youtube !== void 0){

          var __$movie = __$this.find(".movie").empty();
          var __$yt = $('<iframe width="560" height="315" src="https://www.youtube.com/embed/' + _youtube + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
          __$movie.append(__$yt);
        }

        __$this.addClass("modal-content--open");
        $modalOverlay.css({
          "background": _overlay,
          opacity: _opacity,
        });


        var _timer = setTimeout(function(){
          if(__$box.outerHeight() > $window.outerHeight()){
            __$this.addClass("not-centering");
          }

          __$this.scrollTop(0);

          __$this.addClass("show");
          clearTimeout(_timer);
        }, 100);
      }
    });
    $modal.css({display: "block"});
    modalSwiperFunc();
    $modalOverlay.on("click", SelectModalClose);
    $modalClose.on("click", SelectModalClose);
  };

  var SelectModalClose = function(){
    // console.log("SelectModalClose");
    var $modal = $(".modal");
    var $modalOverlay = $modal.find(".modal-overlay");
    var $modalClose = $modal.find(".modal--close");
    var $modalContents = $modal.find(".modal-content");
    var _youtube = $modalContents.data("modal-youtube");

    $modalContents.removeClass("show");
    $modalOverlay.stop().animate({opacity: 0}, 300, function(){
      $modal.css({display: ""});
      $html.removeClass("is-modal");
      $modalContents.each(function(){
        var __$this = $(this);
        __$this
          .css({"background": ""})
          .removeClass("modal-content--open")
          .removeClass("not-centering");
      });
      $modalOverlay.css({
        "background": "",
        opacity: "",
      });

      if(_youtube !== void 0){
        $modalContents.find(".movie").empty();
      }

      $modalOverlay.off("click", SelectModalClose);
      $modalClose.off("click", SelectModalClose);
    });
  };

  $body.on("click", ".modal--btn", SelectModalOpen);

  var AnimationStart = function($container){
    // console.log("AnimationStart");
    var _$wait = $container.find(".wait");
    _$wait.each(function(){
      var __$this = $(this);
      var __delay = __$this.data("delay");
      var __timeout = setTimeout(function(){
        __$this
          .removeClass("wait")
          .addClass("animation");
        clearTimeout(__timeout);
      }, __delay)
    });
  };

  var ResetAnimate = function($container){
    // console.log("ResetAnimate");
    var _$animate = $container.find(".animation");
    var _$animating = $container.find(".animating");
    _$animate.each(function(){
      var __$this = $(this);
      __$this
        .addClass("wait")
        .removeClass("animation");
    });
    _$animating.each(function(){
      var __$this = $(this);
      __$this
        .addClass("waiting")
        .removeClass("animating");
    });

  };

  var windowInnerHeight = window.innerHeight;
  var isEventSetAnimation = true;
  var AnimationScroller = null;
  var SetAnimation = function(){
    var $section = $(".waiting");
    var sectionLen = $section.length;
    var timing, mainHeight, mainTop;
    mainTop = 0;
    timing = windowInnerHeight - (windowInnerHeight / 5);

    if(sectionLen === 0){
      isEventSetAnimation = false;
    } else {
      for(var i = 0; i < sectionLen; i++){
        var $wait = $($section[i]);
        var offsetTop = $wait.offset().top;

        if($window.scrollTop() + timing >= offsetTop && $window.scrollTop() <= offsetTop){
          var _delay = $wait.data("delay");
          if(_delay === void 0){
            _delay = 0;
          }
          $wait.delay(_delay).queue(function(){
            var __$this = $(this);
            __$this.removeClass("waiting").addClass('animating');
            __$this.dequeue();
          });
        }
      }
    }
  };

  // メインのアニメーション
  var StartTopAnimation = function(){
    var _t = setTimeout(function(){
      // ScrollAnimatiopn
      AnimationScroller = function(){
        if(isEventSetAnimation) SetAnimation();
      };
      AnimationStart($("#fv"));
      $window.on("scroll", AnimationScroller);
      clearTimeout(_t);
    }, 100);
  };
  StartTopAnimation();

  $(".anchor").on("click", function(){
    var _$this = $(this);
    var href= _$this.attr("href");
    var _$target = $(href);

    var headerheight = 0;
    if(_isSP){
    }

    $("html, body").stop().animate({scrollTop: _$target.offset().top - headerheight}, 300, "linear");

    if($body.hasClass("nav--open")){
      NavOpenClose();
    }

    return false;
  });

  // RESIZE
  var Resize = function(){
    windowInnerHeight = window.innerHeight
    if($window.width() < 829){
      if(!_isSP) _isSP = true;
    }else{
      if(_isSP) _isSP = false;
    }
  };
  $window.on("resize", Resize);
  Resize();

  var pagetopBtn = $(".pagetop-btn");
  pagetopBtn.on("click", function(){
    $("html, body").stop().animate({scrollTop: 0}, 1000, "easeInOutCirc");
  });

  $body.addClass("loaded");
};

window.addEventListener("load", Init);